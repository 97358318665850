import React from 'react';
import {Breadcrumb, Layout, Space, Row, Col, theme} from 'antd';
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo";
import { CheckCircleTwoTone, HeartTwoTone, SmileTwoTone, BarChartOutlined } from '@ant-design/icons';

import HeaderMenu from '../components/HeaderMenu';
import "./index.css"

const { Content, Footer } = Layout;

const IndexPage = () => {
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();
    return (
        <Layout>
            <Seo title="Hem"/>
            <HeaderMenu selectedKey={"home"} />

            <Content style={{padding: '0 48px'}}>
                <Breadcrumb style={{margin: '16px 0'}}>
                    <Breadcrumb.Item>&nbsp;</Breadcrumb.Item>
                </Breadcrumb>
                <div
                    style={{
                        background: colorBgContainer,
                        minHeight: 650,
                        padding: 24,
                        borderRadius: borderRadiusLG,
                    }}
                >
                    <Space direction="vertical" size="large">
                        <div>
                            Hej! Vi gillar aktier, grafer och data.
                            <br/>
                            <br/>
                            <br/>
                            <BarChartOutlined style={{fontSize: '32px'}}/>
                            <HeartTwoTone twoToneColor="#eb2f96" style={{fontSize: '32px'}}/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                        </div>

                        <div>
                            <Row gutter={[16, 16]}>
                                <Col xs={24} sm={24} md={12} lg={12}>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12}>
                                </Col>
                            </Row>
                        </div>

                    </Space>
                </div>
            </Content>
            <Footer style={{textAlign: 'center'}}>
                aktiedata.com ©{new Date().getFullYear()}
            </Footer>
        </Layout>
    );
};

export default IndexPage;
